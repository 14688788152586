// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import classnames from 'classnames'
import map from 'lodash/map'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import smallKey from '../../methods/small-key'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** List */
const List = ({
  nodes,
  Tag = 'h2',
  Renderer = (
    <div className="list-item">
      <Tag>Need to define a renderer</Tag>
    </div>
  ),
  className,
  itemClassName,
}) => (
  <div className={classnames('list', className)}>
    {map(nodes, (node) => (
      <Renderer
        key={smallKey()}
        node={node}
        Tag={Tag}
        className={classnames('list-item', itemClassName)}
      />
    ))}
  </div>
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default List
