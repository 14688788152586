// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import compose from 'recompose/compose'

import isNull from 'lodash/isNull'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { connectStateResults } from 'react-instantsearch-dom'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import SearchResults from './search-results'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const SearchFeature = ({
  error,
  isSearchStalled,
  searching,
  searchResults,
}) => (
  <Fragment>
    {isSearchStalled === false && (
      <Fragment>
        {isNull(error) === true && (
          <Fragment>
            {searching === false && (
              <Fragment>
                <SearchResults hash="#" nodes={searchResults.hits} />
              </Fragment>
            )}
            {searching === true && <p>Searching...</p>}
          </Fragment>
        )}
      </Fragment>
    )}
    {isSearchStalled === true && (
      <Fragment>
        <p>Working...</p>
      </Fragment>
    )}
  </Fragment>
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedSearchFeature = compose(
  connectStateResults // Connect state
)(SearchFeature)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ComposedSearchFeature
