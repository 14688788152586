// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const LayoutDescriptiveList = (props) => (
  <svg
    width={900}
    height={900}
    viewBox="0 0 900 900"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#000" fillRule="nonzero">
        <path d="M33.871 880h832.258c7.66 0 13.871-6.21 13.871-13.871V477.742c0-7.66-6.21-13.871-13.871-13.871H33.871c-7.66 0-13.871 6.21-13.871 13.87V866.13C20 873.789 26.21 880 33.871 880Zm13.87-388.387h804.517v360.645H47.742V491.613ZM33.871 436.129h832.258c7.66 0 13.871-6.21 13.871-13.87V33.87C880 26.211 873.79 20 866.129 20H33.871C26.211 20 20 26.21 20 33.871v388.387c0 7.66 6.21 13.871 13.871 13.871Zm13.87-388.387h804.517v360.645H47.742V47.742Z" />
      </g>
      <path d="M0 0h900v900H0z" />
    </g>
  </svg>
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default LayoutDescriptiveList
