// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const LayoutMasonry = (props) => (
  <svg
    width={900}
    height={900}
    viewBox="0 0 900 900"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#000" fillRule="nonzero">
        <path d="M275.313 20H33.438C26.015 20 20 26.016 20 33.438v537.5c0 7.42 6.016 13.437 13.438 13.437h241.875c7.42 0 13.437-6.016 13.437-13.438v-537.5c0-7.42-6.016-13.437-13.438-13.437Zm-13.438 537.5h-215V46.875h215V557.5ZM570.938 315.625H329.063c-7.422 0-13.438 6.016-13.438 13.438v537.5c0 7.42 6.016 13.437 13.438 13.437h241.875c7.42 0 13.437-6.016 13.437-13.438v-537.5c0-7.42-6.016-13.437-13.438-13.437Zm-13.438 537.5h-215V342.5h215v510.625ZM866.563 20H624.688c-7.422 0-13.438 6.016-13.438 13.438v537.5c0 7.42 6.016 13.437 13.438 13.437h241.875c7.42 0 13.437-6.016 13.437-13.438v-537.5C880 26.017 873.984 20 866.562 20Zm-13.438 537.5h-215V46.875h215V557.5ZM275.313 611.25H33.438c-7.422 0-13.438 6.016-13.438 13.438v241.875C20 873.982 26.016 880 33.438 880h241.875c7.42 0 13.437-6.016 13.437-13.438V624.688c0-7.42-6.016-13.437-13.438-13.437Zm-13.438 241.875h-215v-215h215v215ZM866.563 611.25H624.688c-7.422 0-13.438 6.016-13.438 13.438v241.875c0 7.42 6.016 13.437 13.438 13.437h241.875c7.42 0 13.437-6.016 13.437-13.438V624.688c0-7.42-6.016-13.437-13.438-13.437Zm-13.438 241.875h-215v-215h215v215ZM570.938 20H329.063c-7.422 0-13.438 6.016-13.438 13.438v241.875c0 7.42 6.016 13.437 13.438 13.437h241.875c7.42 0 13.437-6.016 13.437-13.438V33.438c0-7.42-6.016-13.437-13.438-13.437ZM557.5 261.875h-215v-215h215v215Z" />
      </g>
      <path d="M0 0h900v900H0z" />
    </g>
  </svg>
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default LayoutMasonry
