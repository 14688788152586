// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import classnames from 'classnames'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { SortBy, Stats, connectSearchBox } from 'react-instantsearch-dom'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import { updateView } from '../../state/actions'

// import CompactListLayout from '../svg/layout-compact-list'
import DescriptiveListLayout from '../svg/layout-descriptive-list'
import GridLayout from '../svg/layout-grid'
import MasonryLayout from '../svg/layout-masonry'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

/** [description] */
const SearchFeature = connectSearchBox(({ currentRefinement, refine }) => (
  <input
    type="text"
    label="Type to search"
    title="Type to search"
    placeholder="Type to search"
    value={currentRefinement}
    onChange={(e) => refine(e.target.value)}
    autoComplete="off"
    className="form-control"
  />
))

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const TopBar = ({ view, updateView }) => (
  <Fragment>
    <div className="top-bar search-top-bar">
      <div className="title-and-layouts">
        <SearchFeature />
        <div className="layouts">
          <a
            href="#"
            className={classnames({
              active: view === 'Descriptive Layout',
            })}
            title="Descriptive Layout"
            onClick={(e) => {
              e.preventDefault()
              updateView('Descriptive Layout')
            }}
          >
            <DescriptiveListLayout />
          </a>
          <a
            href="#"
            className={classnames({ active: view === 'Grid Layout' })}
            title="Grid Layout"
            onClick={(e) => {
              e.preventDefault()
              updateView('Grid Layout')
            }}
          >
            <GridLayout />
          </a>
          <a
            href="#"
            className={classnames({ active: view === 'Masonry Layout' })}
            title="Masonry Layout"
            onClick={(e) => {
              e.preventDefault()
              updateView('Masonry Layout')
            }}
          >
            <MasonryLayout />
          </a>
        </div>
      </div>
      <div className="crumbs-and-pages">
        <div>
          <Stats />
        </div>
        <div>
          <SortBy
            defaultRefinement="ark2"
            items={[
              { value: 'ark2', label: 'Most recent first' },
              { value: 'ark2_year_desc', label: 'Oldest first' },
            ]}
          />
        </div>
      </div>
    </div>
  </Fragment>
)

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    view: state.view,
  }),
  (dispatch) => ({
    updateView(payload) {
      dispatch(updateView(payload))
    },
  })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedTopBar = compose(
  withState // Add state
)(TopBar)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ComposedTopBar
