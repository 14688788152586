// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import classnames from 'classnames'
import map from 'lodash/map'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Masonry from 'react-masonry-css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import smallKey from '../../methods/small-key'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** MasonryGrid */
const MasonryGrid = ({
  nodes,
  Renderer = (node) => <h2>Need to define a renderer for {node.title}</h2>,
  className,
  itemClassName,
  breakpoints = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
  },
}) => (
  <Masonry
    breakpointCols={breakpoints}
    className={classnames('grid masonry-grid ext-image-fix', className)}
    columnClassName="masonry-grid-column"
  >
    {map(nodes, (node) => (
      <div className={classnames('grid-item', itemClassName)}>
        <Renderer key={smallKey()} node={node} />
      </div>
    ))}
  </Masonry>
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default MasonryGrid
