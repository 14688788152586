// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import List from '../list'
import '../list/style.less'

import Grid from '../grid'
import MasonryGrid from '../grid/masonry'
import '../grid/style.less'

import DescriptiveListItem from './descriptive-list-item'
import GridItem from './grid-item'
import MasonryGridItem from './masonry-grid-item'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const ArkPageItemsLayouts = React.memo(({ view, nodes }) => (
  <Fragment>
    {view === 'Descriptive Layout' && (
      <List
        nodes={nodes}
        Renderer={DescriptiveListItem}
        className="ark-descriptive-list"
        itemClassName="ark-descriptive-list-item"
      />
    )}
    {view === 'Grid Layout' && (
      <Grid
        nodes={nodes}
        Renderer={GridItem}
        className="ark-grid"
        itemClassName="ark-grid-item"
      />
    )}
    {view === 'Masonry Layout' && (
      <MasonryGrid
        nodes={nodes}
        Renderer={MasonryGridItem}
        className="ark-masonry-grid"
        itemClassName="ark-masonry-grid-item"
        breakpoints={{
          555: 1,
          840: 2,
          1110: 3,
          1400: 4,
          1680: 5,
          1950: 6,
          2225: 7,
          2500: 8,
        }}
      />
    )}
  </Fragment>
))

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    view: state.view,
  })
  // (dispatch) => ({
  //   // updateHeaderState(payload) {
  //   //   dispatch(updateHeaderState(payload))
  //   // },
  // })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedArkPageItemsLayouts = compose(
  withState // Add state
)(ArkPageItemsLayouts)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ComposedArkPageItemsLayouts
