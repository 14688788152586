// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import isEmpty from 'lodash/isEmpty'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import ArkImg from '../ark-img'
import '../ark-img/style.less'

import Link from '../link'
import '../link/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
const DescriptiveListItem = React.memo(
  ({
    className,
    node: { arkRef, fileRef, title, description, keywords, ar, year },
  }) => (
    <Link
      to={`https://web.auroville-archives.org/${arkRef}`}
      className={className}
      title={`Click to see more details for "${title}"`}
    >
      <ArkImg fileRef={fileRef} aspectRatio={ar} width={200} title={title} />
      <div className="title">
        <h2 className="unset">{title}</h2>
        <p>
          Publisher: Auroville Archives
          <br />
          Year: {year}
          <br />
          {isEmpty(description) === false && (
            <Fragment>
              Description: {description}
              <br />
            </Fragment>
          )}
        </p>
      </div>
    </Link>
  )
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default DescriptiveListItem
